import { Box, Heading } from 'grommet'
import useI18n from '../../hooks/useI18n'
import { useAppSelector } from '../../services/storeProvider'
import { useCallback, useEffect, useState } from 'react'
import useSize from '../../hooks/useSize'
import BLineChart from '../BLineChart'
import { addMonths, format, parse, startOfMonth } from 'date-fns'
import { AddressModel, ConsumoModel, SupplyModel } from '../../models/models'
import { it } from 'date-fns/locale'
import useAppQuery from '../../hooks/useAppQuery'
import {
    loadSupplies,
    loadSuppliesRecontracted,
} from '../../services/store/actions'
import loadInvoices from '../../services/store/actions/loadInvoices'
import loadConsumptions from '../../services/store/actions/loadConsumptions'

type ConsumiChartDataModel = {
    label: string
    value: number
    unit: string
}
type ConsumiChartModel = {
    date: Date
    amount: number
}

const CURRENT_MONTH = startOfMonth(new Date())
const LAST_YEAR = addMonths(CURRENT_MONTH, -11)

const SupplyChart = () => {
    const strings = useI18n()
    const size = useSize()
    const supply = useAppSelector((state) => state.supply)
    const user = useAppSelector((state) => state.user.clientInfo)
    const [isLoadingSupplies, querySupplies] = useAppQuery(loadSupplies)
    const [isLoadingInvoices, queryInvoices] = useAppQuery(loadInvoices)
    const [isLoadingConsumptions, queryConsumptions] =
        useAppQuery(loadConsumptions)

    const [showModalSupplies, setShowModalSupplies] = useState(false)
    const [isLoadingSuppliesRecontracted, queryRecontractedSupplies] =
        useAppQuery(loadSuppliesRecontracted)

    const [graphData, setGraphData] = useState<ConsumiChartDataModel[]>([
        { label: 'Gen', value: 0, unit: 'mc' },
        { label: 'Feb', value: 0, unit: 'mc' },
        { label: 'Mar', value: 0, unit: 'mc' },
        { label: 'Apr', value: 0, unit: 'mc' },
        { label: 'Mag', value: 0, unit: 'mc' },
        { label: 'Giu', value: 0, unit: 'mc' },
        { label: 'Lug', value: 0, unit: 'mc' },
        { label: 'Ago', value: 0, unit: 'mc' },
        { label: 'Set', value: 0, unit: 'mc' },
        { label: 'Ott', value: 0, unit: 'mc' },
        { label: 'Nov', value: 0, unit: 'mc' },
        { label: 'Dic', value: 0, unit: 'mc' },
    ])

    const [graphSections, setGraphSections] = useState([
        { id: 'E', label: `${strings.E}`, active: true },
        { id: 'G', label: `${strings.G}`, active: false },
    ])

    const loadConsumptionsData = useCallback(
        async (
            actualConsumptions: Array<ConsumoModel>,
            selectedAddress?: AddressModel,
        ) => {
            const consumiFilteredAdded: Array<ConsumoModel> = []
            const _consumiGraficati: ConsumiChartModel[] = []
            let consumiFiltered: Array<ConsumoModel>
            if (selectedAddress) {
                consumiFiltered = actualConsumptions.filter(
                    (it) => selectedAddress.puntiGas.indexOf(it.puntoGas) > -1,
                )
            } else {
                consumiFiltered = actualConsumptions
            }
            let i = 0
            consumiFiltered.forEach((el) => {
                el = {
                    ...el,
                    id: i,
                }

                i++
                consumiFilteredAdded.push(el)

                const elge = {
                    date: parse(el.dataLettura, 'yyyy-MM-dd', new Date()),
                    amount:
                        (el.consumoF1 && el.consumoF1 !== ''
                            ? +el.consumoF1
                            : 0) +
                        (el.consumoF2 && el.consumoF2 !== ''
                            ? +el.consumoF2
                            : 0) +
                        (el.consumoF3 && el.consumoF3 !== ''
                            ? +el.consumoF3
                            : 0),
                }

                if (
                    el.tipoServizio ===
                    graphSections.find((el) => el.active)?.id
                ) {
                    _consumiGraficati.push(elge)
                }
            })
            _consumiGraficati.sort(compare)

            const graphData: { [key: string]: ConsumiChartDataModel } = {}

            let dt = LAST_YEAR
            while (dt <= CURRENT_MONTH) {
                const stringFormat =
                    dt.getMonth() === 0 || dt.getMonth() === 11
                        ? 'MMM yyyy'
                        : 'MMM'
                const month = format(dt, stringFormat, { locale: it })

                graphData[month] = {
                    label: month,
                    value: 0,
                    unit: 'mc',
                }
                dt = addMonths(dt, 1)
            }

            for (const consumi of _consumiGraficati) {
                if (consumi.date < LAST_YEAR) continue

                const stringFormat =
                    consumi.date.getMonth() === 0 ||
                    consumi.date.getMonth() === 11
                        ? 'MMM yyyy'
                        : 'MMM'
                const month = format(consumi.date, stringFormat, { locale: it })

                graphData[month].value = graphData[month].value + consumi.amount
            }

            setGraphData(Object.values(graphData))
        },
        [graphSections],
    )

    const loadSuppliesData = useCallback(async () => {
        try {
            const supplies = await querySupplies(user.codiceUtente)
            await queryInvoices({
                codiceUtente:
                    supply.selectedSupply?.codiceUtente ?? user.codiceUtente,
                puntoGas: supply.selectedSupply?.puntoGas,
            })
            const consumptions = await queryConsumptions(supplies)
            loadConsumptionsData(consumptions)

            const isGplSectionPresent = graphSections.some(
                (section) => section.id === 'GPL',
            )

            if (
                supplies.find((it: SupplyModel) => it.tipoServizio === 'GPL') &&
                !isGplSectionPresent
            ) {
                const updatedGraphSections = [
                    ...graphSections,
                    { id: 'GPL', label: `${strings.GPL}`, active: false },
                ]
                setGraphSections(updatedGraphSections)
            }
        } catch (e) {
            console.error('Error loading supplies data', e)
        }
    }, [
        graphSections,
        loadConsumptionsData,
        queryConsumptions,
        queryInvoices,
        querySupplies,
        strings.GPL,
        supply.selectedSupply?.codiceUtente,
        supply.selectedSupply?.puntoGas,
        user.codiceUtente,
    ])
    useEffect(() => {
        if (!user.codiceUtente) {
            return
        }
        queryRecontractedSupplies(user.codiceUtente)
        setShowModalSupplies(true)
    }, [queryRecontractedSupplies, user.codiceUtente])

    useEffect(() => {
        if (!user.codiceUtente) {
            return
        }
        loadSuppliesData()
    }, [user.codiceUtente, supply.selectedSupply, loadSuppliesData])

    useEffect(() => {
        if (!supply.selectedAddress) {
            return
        }
        loadConsumptionsData(supply.consumptions, supply.selectedAddress)
    }, [
        supply.selectedAddress,
        graphSections,
        loadConsumptionsData,
        supply.consumptions,
    ])

    function compare(a: any, b: any) {
        if (a.date < b.date) {
            return -1
        }
        if (a.date > b.date) {
            return 1
        }
        return 0
    }

    const selectedSectionColor = () => {
        const currentSection = graphSections.find((it) => it.active)?.id

        if (!currentSection) {
            return // default color
        }

        return currentSection === 'E'
            ? 'ee'
            : currentSection === 'GPL'
            ? 'gpl'
            : 'gas'
    }

    return (
        <Box
            width={{
                min: '15vw',
                width: size === 'large' ? '70%' : '100%',
            }}
        >
            <Heading size="small">{strings.consumiLasyYear}</Heading>
            <Box flex gap="small">
                <BLineChart
                    address={
                        supply.selectedAddress
                            ? `${supply.selectedAddress?.tipoVia} ${supply.selectedAddress?.via}, ${supply.selectedAddress?.comune} ${supply.selectedAddress?.provincia}`
                            : strings.tutteForniture
                    }
                    sections={graphSections}
                    onSectionClick={(id) => {
                        const newSections = graphSections.map((it) => ({
                            ...it,
                            active: it.id === id,
                        }))
                        setGraphSections(newSections)
                    }}
                    data={graphData}
                    onBarClick={(index) => {
                        const newData = graphData.map((it, idx) => ({
                            ...it,
                            active: index === idx,
                        }))
                        setGraphData(newData)
                    }}
                    graphColor={selectedSectionColor()}
                />
            </Box>
        </Box>
    )
}

export default SupplyChart
