import { CityProvinceModel, SupportModel } from '../../models/models'
import * as Yup from 'yup'
import { Formik } from 'formik'
import useI18n from '../../hooks/useI18n'
import BTextInput from '../BTextInput'
import { Box, Button, Spinner } from 'grommet'
import BSelect from '../BSelect'
import BButton from '../BButton'

const SupportForm = ({
    streetTypes,
    cities,
    initialValues,
    availableRequests,
    onSubmit,
    isLoading = false,
}: SupportFormProps) => {
    const strings = useI18n()
    const supportSchema = Yup.object().shape({
        name: Yup.string().required(strings.mandatoryField),
        subrequest: Yup.string().required(strings.mandatoryField),
        note: Yup.string().notRequired(),
        description: Yup.string().required(strings.mandatoryField),
        preferredContactTime: Yup.string().notRequired(),
        phone: Yup.string()
            .required(strings.mandatoryField)
            .matches(/^\d{6,12}$/, strings.telCheck),
        email: Yup.string()
            .required(strings.mandatoryField)
            .email(strings.invalidEmailField),
        address: Yup.string().required(strings.mandatoryField),
        cap: Yup.string().required(strings.mandatoryField),
        number: Yup.number()
            .typeError(strings.mustBeNumber)
            .required(strings.mandatoryField),
        barrato: Yup.string().notRequired(),
        province: Yup.string().required(strings.mandatoryField),
        city: Yup.string().required(strings.mandatoryField),
        streetType: Yup.string()
            .required(strings.mandatoryField)
            .test('Valid', strings, (value) => {
                return streetTypes.includes(value || '')
            }),
    })
    return (
        <Formik
            initialValues={{
                name: initialValues?.name || '',
                note: initialValues?.note || '',
                subrequest: initialValues?.subrequest || '',
                description: initialValues?.description || '',
                preferredContactTime: initialValues?.preferredContactTime || '',
                phone: initialValues?.phone || '',
                email: initialValues?.email || '',
                address: initialValues?.address || '',
                cap: initialValues?.cap || '',
                number: initialValues?.number || '',
                barrato: initialValues?.barrato || '',
                province: initialValues?.province || '',
                city: initialValues?.city || '',
                streetType: initialValues?.streetType || '',
                cityIstat: initialValues?.cityIstat || '',
            }}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
            validationSchema={supportSchema}
            onSubmit={(values) => onSubmit(values)}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
            }) => {
                const cityList = values.province
                    ? cities.find(
                          (it) =>
                            (it?.codiceProvincia?.toLowerCase() ?? '') === (values?.province?.toLowerCase() ?? ''),
                      )?.comune ?? []
                    : []
                const city = cityList.find(
                    (it) => it.nomeComune === values.city,
                )
                const capList =
                    cityList.find((it) => (it?.nomeComune ?? '') === (values?.city ?? ''))
                        ?.cap ?? ([] as Array<string>)

                values.city = city?.nomeComune ?? ''
                values.cityIstat = city?.codiceIstat ?? ''
                return (
                    <Box gap="small">
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.cognomeNomeRecapito}
                                label={strings.cognomeNomeRecapito}
                                name="name"
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.contactTime}
                                label={strings.contactTime}
                                name="preferredContactTime"
                            />
                        </Box>
                        <BSelect
                            label={strings.tipoRichiesta}
                            placeholder={strings.tipoRichiesta}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            name="subrequest"
                            errors={errors}
                            options={availableRequests}
                            labelKey="label"
                            valueKey={{ key: 'value', reduce: true }}
                        />
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.contactNotes}
                                label={strings.contactNotes}
                                name="note"
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.description}
                                label={strings.description}
                                name="description"
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.telefono}
                                label={strings.telefono}
                                name="phone"
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.emailPlaceholder}
                                label={strings.email}
                                name="email"
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                        >
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.province}
                                placeholder={strings.province}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                name="province"
                                errors={errors}
                                icon={isLoading ? <Spinner /> : true}
                                options={cities.map((it) => ({
                                    label: it.provincia,
                                    value: it.codiceProvincia,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.city}
                                placeholder={strings.city}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                name="city"
                                icon={isLoading ? <Spinner /> : true}
                                options={cityList.map((it: any) => ({
                                    label: it.nomeComune,
                                    value: it.nomeComune,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.cap}
                                placeholder={strings.cap}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                name="cap"
                                icon={isLoading ? <Spinner /> : true}
                                options={capList.map((it) => ({
                                    label: it,
                                    value: it,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BSelect
                                searchable
                                containerProps={{ flex: true }}
                                label={strings.streetType}
                                placeholder={strings.streetType}
                                name="streetType"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                options={streetTypes.map((it) => ({
                                    label: it.replace(
                                        /^(\w)(.+)/,
                                        (_, p1, p2) =>
                                            p1.toUpperCase() + p2.toLowerCase(),
                                    ),
                                    value: it,
                                }))}
                                labelKey="label"
                                valueKey={{ key: 'value', reduce: true }}
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.streetName}
                                label={strings.streetName}
                                name="address"
                            />
                        </Box>
                        <Box
                            direction="row"
                            flex
                            gap="small"
                            align="flex-start"
                            wrap
                        >
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.streetNumber}
                                label={strings.streetNumber}
                                name="number"
                            />
                            <BTextInput
                                containerProps={{ flex: true }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                placeholder={strings.streetSepatator}
                                label={strings.streetSepatator}
                                name="barrato"
                            />
                        </Box>
                        <Box margin={{ top: 'small' }}>
                            <Button
                                primary
                                label={strings.sendSupportRequest}
                                disabled={isLoading}
                                icon={isLoading ? <Spinner /> : undefined}
                                onClick={async () => {
                                    await validateForm()
                                    handleSubmit()
                                }}
                            />
                        </Box>
                    </Box>
                )
            }}
        </Formik>
    )
}

type SupportFormProps = {
    initialValues?: SupportModel
    streetTypes: Array<string>
    cities: Array<CityProvinceModel>
    availableRequests: Array<{ label: string; value: string }>
    onSubmit: (values: SupportModel) => any
    isLoading: boolean
}

export default SupportForm
